import * as React from "react";
import {
  Button, Container, Grid, Header, Segment
} from "semantic-ui-react";
import {t} from "../../i18n";
import '../../css/quality.css';
import scrollTo from 'gatsby-plugin-smoothscroll';
import { StaticImage } from "gatsby-plugin-image";
import QualityBackground from '../backgrounds/quality/Quality';
import QualityStandardsBackground from "../backgrounds/quality/QualityStandards";
import QualityLabBackground from "../backgrounds/quality/QualityLab";
import SEO from "../seo";
import QCloud from "../backgrounds/others/QCloud";

export default class Quality extends React.Component {
  render() {
    return (
        <Segment basic id="quality-banner" style={{padding: 0}}>
          <SEO title="Certyfikaty jakości suplementów personalizowanych"
            description="Jakość suplementów Pure4me potwierdzona certyfikatami GMP – dla produktów leczniczych, normą ISO 9001:2008, według Standard IFS i Standard BRC."
          />
          <QualityBackground>
            <Container>
              <section id='qual-hero-section'>
                <Grid columns={2} stackable>
                  <Grid.Column>
                    <Header id='qual-header-title'>
                      {t("Jakość")}
                    </Header>
                    <p id="qual-paragraph">
                      Twój suplement tworzymy w warunkach farmaceutycznych w fabryce, która wykorzystuje <b>najnowocześniejsze technologie badawcze</b> i produkcyjne przy jednoczesnym spełnianiu
                      kryteriów <b>ochrony środowiska</b>.
                    </p>

                    <p id="qual-paragraph">
                      Dzięki ponad <b>20-letniemu doświadczeniu w produkcji leków, wyrobów medycznych i suplementów
                      diety</b> zapewniamy
                      pewność wysokiej jakości.
                    </p>
                    <p id="qual-paragraph">
                      <b>Własne laboratorium,</b> które posiadamy, pozwala nam na szczegółowe kontrole na każdym etapie
                      produkcji od
                      surowca do końcowego produktu, zapewniając konsumentom większy poziom komfortu i <b>pewność wysokiej
                      jakości</b> wybieranych produktów.
                    </p>

                    <p id="qual-paragraph">
                      Jakość naszych produktów <b>potwierdzona jest przez liczne certyfikaty</b></p>
                    <Button secondary id='qual-btn' onClick={() => scrollTo('#certificates')}>
                      {t("zobacz nasze certyfikaty")}!
                    </Button>
                  </Grid.Column>
                  <Grid.Column verticalAlign="middle">
                    <StaticImage src="../../img/quality.png" alt="Jakość" placeholder="none"/>
                  </Grid.Column>
                </Grid>
              </section>
            </Container>
          </QualityBackground>
          <QualityLabBackground>
            <Container>
                <section id='qual-desc-section'>
                  {/* <Grid>
                    <Grid.Row>
                      <Grid.Column textAlign="center">
                        <p>
                          Dzięki ponad <b>20-letniemu doświadczeniu w produkcji leków, wyrobów medycznych i suplementów
                          diety</b> zapewniamy
                          pewność wysokiej jakości.
                        </p>
                        <p>
                          <b>Własne laboratorium,</b> które posiadamy, pozwala nam na szczegółowe kontrole na każdym etapie
                          produkcji od
                          surowca do końcowego produktu, zapewniając konsumentom większy poziom komfortu i pewność wysokiej
                          jakości
                          wybieranych produktów.
                        </p>

                        <p>
                          Jakość naszych produktów potwierdzona jest przez liczne certyfikaty</p>
                      </Grid.Column>
                    </Grid.Row>
                  </Grid> */}
                </section>
                </Container>
              </QualityLabBackground>
              <QualityStandardsBackground>
                <Container>
              <a className="anchor" id='certificates' />
              <QCloud>
                <section id="qual-cert">
                  <Grid stackable>
                    <Grid.Column width={4}>
                      <Segment basic className="cert">
                        <Grid textAlign="center">
                          <Grid.Row>
                            <StaticImage src='../../img/qi_01.png' alt="GMP dla produktów leczniczych" placeholder='none' />
                          </Grid.Row>
                          <Grid.Row>
                            <p>
                              <b>GMP – dla produktów leczniczych</b>
                            </p>
                          </Grid.Row>
                          <Grid.Row>
                            <p id="qual-cert-paragraph">
                              gwarantuje pełną kontrolę nad jakością i pochodzeniem surowców ale również bezpieczeństwo
                              środowiska.
                            </p>
                          </Grid.Row>
                        </Grid>
                      </Segment>
                    </Grid.Column>


                    <Grid.Column width={4}>
                      <Segment basic className="cert">
                        <Grid textAlign="center">
                          <Grid.Row>
                            <StaticImage src='../../img/qi_02.png' alt="Norma ISO 9001:2008" placeholder='none' />
                          </Grid.Row>
                          <Grid.Row>
                            <b>Norma ISO 9001:2008</b>
                          </Grid.Row>
                          <Grid.Row>
                            <p id="qual-cert-paragraph">
                              określająca wszelkie wymagania, które spełnia system zarządzania jakością w fabryce.
                            </p>
                          </Grid.Row>
                        </Grid>
                      </Segment>
                    </Grid.Column>


                    <Grid.Column width={4}>
                      <Segment basic className="cert">
                        <Grid textAlign="center">
                          <Grid.Row>
                            <StaticImage src='../../img/qi_03.png' alt="Standard IFS" placeholder='none' />
                          </Grid.Row>
                          <Grid.Row>
                            <p>
                              <b>Standard IFS (International Food Standard)</b>
                            </p>
                          </Grid.Row>
                          <Grid.Row>
                            <p id="qual-cert-paragraph">
                              obejmujące rozwój, produkcję i sprzedaż suplementów
                              diety, w formie tabletek, kapsułek, proszków oraz syropów.
                            </p>
                          </Grid.Row>
                        </Grid>
                      </Segment>
                    </Grid.Column>


                    <Grid.Column width={4}>
                      <Segment basic className="cert">
                        <Grid textAlign="center">
                          <Grid.Row>
                            <StaticImage src='../../img/qi_04.png' alt="Standard BRC" placeholder='none' />
                          </Grid.Row>
                          <Grid.Row>
                            <p>
                              <b>Standard BRC (British Retail Consortium) Global Standards</b>
                            </p>
                          </Grid.Row>
                          <Grid.Row>
                            <p id="qual-cert-paragraph">
                              stanowi gwarancję kompetencji i możliwości
                              działania dostawcy, jednocześnie zwalniając odbiorców z konieczności przeprowadzania własnych
                              audytów i
                              zdejmując z obu stron część ciężaru działań administracyjnych.
                            </p>
                          </Grid.Row>
                        </Grid>
                      </Segment>
                    </Grid.Column>
                  </Grid>
                </section>
              </QCloud>

            </Container>
          </QualityStandardsBackground>
        </Segment>
    );
  }
}