import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import { BackgroundImg } from '../BackgroundImg';

const QualityLabBackground = ({children}) => {
  const data = useStaticQuery(
    graphql`
      query {
        desktop: file(relativePath: { eq: "backgrounds/quality/section_2_quality_bg.jpg" }) {
          childImageSharp {
            gatsbyImageData(
              quality: 90
              placeholder: BLURRED
              formats: [AUTO, WEBP, AVIF]
            )
          }
        }
      }
    `
  )
  

  return (
    <BackgroundImg image={data.desktop} style={{backgroundPosition: 'bottom center'}}>
      {children}
    </BackgroundImg>
  )
}

export default QualityLabBackground;
